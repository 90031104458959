import React from 'react';
import adsList from '../data/ads';

export default function Sidebar() {
	
	for (let i = adsList.length-1; i>0; i--) {
	  const j = Math.floor(Math.random() * (i + 1));
	  [adsList[i], adsList[j]] = [adsList[j], adsList[i]];
	}

	return (
		<aside>
			{adsList.slice(0, 4).map((ads, idx) => 
				<React.Fragment key={idx}>
					{ads}
				</React.Fragment>
				)}
		</aside>
	);
}
