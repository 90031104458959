import { Link } from "react-router-dom";
import SocialLinks from "./SocialLinks";
import logo from '../imgs/travolgi-logo.webp';

export default function Footer() {
	const currentDate = new Date().getFullYear();
	return (
		<footer>
			<div className="container">
				<div>
					<img src={logo} alt="Travolgi Themes logo" />
					<p>&copy; Travolgi Themes {currentDate}. PLLNNA65R60A703G.</p>
				</div>

				<ul>
					<li>Useful link:</li>
					<li>
						<Link to='/themes'>Website Template & Themes</Link>
					</li>
					<li>
						<Link to='/what-we-use'>What We Use</Link>
					</li>
					<li>
						<a href="https://www.iubenda.com/privacy-policy/81010026/legal" target="_blank" rel="noreferrer">Privacy Policy</a>
					</li>
					<li>
						<Link to='/cookie-policy'>Cookie Policy</Link>
					</li>
				</ul>

				<SocialLinks />
			</div>
		</footer>
	);
}