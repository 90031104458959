import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import articlesList from '../data/articles';
import SectionPageTitle from '../components/SectionPageTitle';
import SocialShare from '../components/SocialShare';
import Sidebar from '../components/Sidebar';
import PageNotFound from './PageNotFound';

export default function Article() {
	const { articleName } = useParams(),
			article = articlesList.filter(article => article.slug.includes(articleName))[0];

	if (article) {
		return (
			<>
				<Helmet>
					<title>{article.title} &bull; Blog &bull; Travolgi Themes</title>
				</Helmet>

				<SectionPageTitle
					pageTitle={article.title}
					subTitle={`${article.date} • ${article.category}`}
					bgImg={article.imgs}
				/>

				<section className="container grid-sidebar">
					<article>
						{article.content}

						<SocialShare title={article.title} img={article.imgs.v} />
					</article>

					<Sidebar />
				</section>
			</>
		);
	} else {
		return <PageNotFound />
	}
}
