import { Helmet } from 'react-helmet';
import useWindowWidth from '../../hooks/useWindowWidth';
import PageIndex from '../../components/PageIndex';
import halloweenSaleBanner from '../../imgs/ads/halloween-sale-banner.webp';
import halloweenTheme from '../../imgs/ads/halloween-theme.webp';

export default function HalloweenSaleTemplateMonster() {
	const windowWidth = useWindowWidth();

	return (
		<>
			<Helmet>
				<meta name="description" content="Amazing Discounts are waiting for You at TemplateMonster! Up to 30% on all TemplateMonster products: take advantage now and Buy our Sled theme at a favorable price!" />
				<meta name="keywords" content="sled template, halloween template, sled theme, halloween website themes, holiday sale, halloween, discount, special discount, special sale, marketplace sale, festive discounts, spooky sale, costume, customize your site, template monster, TemplateMonster, MonsterONE, fully responsive, website, wordpress website, business website, html website" />
			</Helmet>

			<PageIndex
				links={[
					{
						hash: 'introduction',
						title: 'Introduction'
					},
					{
						hash: 'halloween-sale',
						title: 'TemplateMonster halloween sales'
					},
					{
						hash: 'halloween-theme',
						title: 'Our New Halloween Theme'
					},
					{
						hash: 'conclusion',
						title: 'In conclusion'
					}
				]}
			/>

			<section>
				<span className="sr-only offset-nav" id="introduction" />
				<p>It is October, and it means that the time has come to become spooky. The <strong>marketplace</strong> is preparing its costume and some <strong>festive discounts</strong>. Your task is to get familiar with all the details to become our guest in time.</p>

				<ul className="list mb-0">
					<li><strong>When?</strong> October 24-31</li>
					<li><strong>What discount?</strong> Up to 30% on all <strong>TemplateMonster</strong> products and services + a discount on MonsterONE</li>
				</ul>
			</section>

			<section className="grid g-3">
				<span className="sr-only offset-nav" id="halloween-sale" />
				<div>
					<h2>Let’s Get Wicked With TemplateMonster and Its Halloween Sales!</h2>
					<p>If you are reading these words while carving a pumpkin, I will not be surprised. <strong>Halloween</strong> is around the corner, and it is ready to bring an incredible <strong>holiday sale</strong>. TemplateMonster is here to give you some treats that you really expected. These include <strong>up to 30%</strong> on all TemplateMonster and services. In addition to this, you will get a special discount on <strong>MonsterONE</strong>.</p>
					<p>Share the Festive Mood on Halloween With Discounts From <strong>TemplateMonster</strong>!</p>
					
					<a href="https://www.templatemonster.com?utm_campaign=halloween_sale&utm_source=article_website&utm_medium=referral&aff=travolgi" target="_blank" className="btn">Shop now!</a>
				</div>

				<a href="https://www.templatemonster.com?utm_campaign=halloween_sale&utm_source=article_website&utm_medium=referral&aff=travolgi" target="_blank">
					<img src={halloweenSaleBanner} className="br-1" alt="Festive Mood on Halloween With Discounts From TemplateMonster • Blog Travolgi Themes" />
				</a>
			</section>
			

			<section className="grid g-3">
				<span className="sr-only offset-nav" id="halloween-theme" />
				{windowWidth > 810 &&
					<a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=halloween_sale&utm_source=article_website&utm_medium=referral&aff=travolgi" target="_blank">
						<img src={halloweenTheme} className="br-1" alt="Our Halloween Theme • Blog Travolgi Themes" />
					</a>
				}

				<div>
					<h2>Our NEW amazing Halloween theme</h2>
					<p className={windowWidth < 810 ? 'mb-3' : null}>Our <strong>Sled template</strong> is perfect if you like a clean, modern and creative template with high performance. We have created a demo all for <strong>Halloween</strong>! It's super easy to customize, so it fits your needs: choose one of the styles or easily <strong>customize your site</strong> following your ideas.</p>
					
					{windowWidth < 810 &&
						<a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=halloween_sale&utm_source=article_website&utm_medium=referral&aff=travolgi" target="_blank">
							<img src={halloweenTheme} className="br-1 mb-3" alt="Our Halloween Theme • Blog Travolgi Themes" />
						</a>
					}

					<h3>Features</h3>
					<ul className="list">
						<li><strong>Fully Responsive</strong> & Mobile Friendly</li>
						<li>Super Fast & <strong>High Performance</strong></li>
						<li>SEO Friendly</li>
						<li>Modern & Clean Design</li>
						<li>Clean Code Structure</li>
						<li>Well Documentation</li>
						<li>Friendly Support</li>
					</ul>

					<a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=halloween_sale&utm_source=article_website&utm_medium=referral&aff=travolgi" target="_blank" className="btn">Buy Sled Theme now!</a>
				</div>
			</section>


			<section>
				<span className="sr-only offset-nav" id="conclusion" />
				<h2>In conclusion</h2>
				<p>Don't miss the incredible bargains that <strong>Halloween discounts offer on TemplateMonster</strong>. Take advantage now and <a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=halloween_sale&utm_source=article_website&utm_medium=referral&aff=travolgi" target="_blank">Buy our Sled theme</a> at a favorable price! Do not miss the opportunity and run to choose your costume for Halloween.</p>
			</section>
		</>
	);
}