import { Helmet } from 'react-helmet';
import useWindowWidth from '../../hooks/useWindowWidth';
import PageIndex from '../../components/PageIndex';
import sledTheme from '../../imgs/ads/halloween-theme.webp';
import sledXdTheme from '../../imgs/blog/sled-xd.webp';
import astroTheme from '../../imgs/ads/astro-theme.webp';

export default function BlackFriday2022() {
	const windowWidth = useWindowWidth();

	return (
		<>
			<Helmet>
				<meta name="description" content="It's November and it means that the time has come to take advantage of the super black friday discounts: do not be late and Get Your 50% OFF on our themes." />
				<meta name="keywords" content="black friday discounts, black friday, themes, cyber monday, html theme, discounts, flexbox and grid layouts, restaurant menu, portfolio functionality, UI Kit, Adobe XD, Crypto website, NFT design, sled theme, website themes, gym website, restaurant website, multipurpose template, marketplace sale, templatemonster, MonsterONE, fully responsive, website" />
			</Helmet>

			<PageIndex
				links={[
					{
						hash: 'introduction',
						title: 'Introduction'
					},
					{
						hash: 'react-theme',
						title: 'Sled React Website template'
					},
					{
						hash: 'xd-theme',
						title: 'Sled UI Kit for Adobe XD'
					},
					{
						hash: 'html-theme',
						title: 'Astro Html Website template - coming soon'
					},
					{
						hash: 'conclusion',
						title: 'In conclusion'
					}
				]}
			/>
			
			<section>
				<span className="sr-only offset-nav" id="introduction" />
				<p className="mb-0">It's November and it means that the time has come to take advantage of the <strong>super black friday discounts</strong>. You can't imagine how many tasks our team has to complete: we are working on new spectacular themes both free and premium. An <strong>html theme</strong> dedicated to the crypto world is coming soon but in the meantime you can take advantage of the super discounts in our current themes displayed below.</p>
			</section>

			<section className="grid g-3">
				<span className="sr-only offset-nav" id="react-theme" />
				<div>
					<h2>Sled | React Website template for Halloween Gym, Restaurant, Bar, and Multipurpose Template</h2>
					<p className={windowWidth < 810 ? 'mb-3' : null}>A <strong>high-performance and clean website template</strong> that allows crafting the desired site without obtaining coding knowledge. You are free to choose from 4+ demo variations. All of them have a <strong>fully-responsive design</strong> that adjusts to all the devices and their screens. There are also flexbox and grid layouts available.</p>
					
					{windowWidth < 810 &&
						<a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=blackfriday_sale&utm_source=article_website&utm_medium=referral&aff=travolgi" target="_blank">
							<img src={sledTheme} className="br-1 mb-3" alt="Sled high-performance and clean website template • Blog Travolgi Themes" />
						</a>
					}

					<h3>Main Features</h3>
					<ul className="list">
						<li>Google Fonts</li>
						<li>Google <strong>Maps</strong></li>
						<li>Team members</li>
						<li><strong>Restaurant menu</strong></li>
						<li><strong>Portfolio</strong> functionality</li>
						<li>Well Documentation</li>
					</ul>
					
					<div className="flex g-2">
						<a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=blackfriday_sale&utm_source=article_website&utm_medium=referral&aff=travolgi" target="_blank" className="btn">Buy Now!</a>

						<a href="https://demo.templatemonster.com/demo/252058.html?utm_campaign=blackfriday_sale&utm_source=article_website&utm_medium=referral&aff=travolgi" target="_blank">Demo</a>

						<a href="https://www.templatemonster.com/monsterone/tm-membership/?id=252058?utm_campaign=blackfriday_sale&utm_source=article_website&utm_medium=referral&aff=travolgi" target="_blank">Download for Free in MonsterONE</a>
					</div>
				</div>

				{windowWidth > 810 &&
					<a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=blackfriday_sale&utm_source=article_website&utm_medium=referral&aff=travolgi" target="_blank">
						<img src={sledTheme} className="br-1" alt="Sled high-performance and clean website template • Blog Travolgi Themes" />
					</a>
				}
			</section>
			

			<section className="grid g-3">
				<span className="sr-only offset-nav" id="xd-theme" />
				{windowWidth > 810 &&
					<a href="https://www.templatemonster.com/ui-elements/sled-gyms-beer-restaurants-ui-kit-for-adobe-xd-251261.html?utm_campaign=blackfriday_sale&utm_source=article_website&utm_medium=referral&aff=travolgi" target="_blank">
						<img src={sledXdTheme} className="br-1" alt="Sled UI Kit for Adobe XD template • Blog Travolgi Themes" />
					</a>
				}

				<div>
					<h2>Sled | Gyms, Beer, Restaurants UI Kit for Adobe XD</h2>
					<p className={windowWidth < 810 ? 'mb-3' : null}>Black Friday from Travolgi will not be full without this engaging theme. It can help you promote a <strong>gym, a beer pub, and a bar business</strong>. There are three highly professional demos. All of them are fully layered and make sure your content will be showcased in the best possible light.</p>
					
					{windowWidth < 810 &&
						<a href="https://www.templatemonster.com/ui-elements/sled-gyms-beer-restaurants-ui-kit-for-adobe-xd-251261.html?utm_campaign=blackfriday_sale&utm_source=article_website&utm_medium=referral&aff=travolgi" target="_blank">
							<img src={sledXdTheme} className="br-1 mb-3" alt="Sled UI Kit for Adobe XD template • Blog Travolgi Themes" />
						</a>
					}

					<h3>Main Features</h3>
					<ul className="list">
						<li>Pixel <strong>perfect design</strong></li>
						<li>Fully-responsive design</li>
						<li>Designed using Google Fonts</li>
						<li>Hover layers are included</li>
						<li><strong>Pre-saved components, colors, and fonts</strong></li>
					</ul>
					
					<a href="https://www.templatemonster.com/ui-elements/sled-gyms-beer-restaurants-ui-kit-for-adobe-xd-251261.html?utm_campaign=blackfriday_sale&utm_source=article_website&utm_medium=referral&aff=travolgi" target="_blank" className="btn">Buy Now!</a>
				</div>
			</section>


			<section className="grid g-3">
				<span className="sr-only offset-nav" id="html-theme" />
				<div>
					<h3>coming soon</h3>
					<h2>Astro Crypto and Multipurpose html5 Website template</h2>
					<p className={windowWidth < 810 ? 'mb-3' : null}>We are building a <strong>Crypto and NFT world</strong> themed website template with clean design and high performance developed in <strong>html5, css3, sass and vanila javascript</strong>. Thanks to a flexbox and grid layout the <strong>design is fully responsive</strong> that adapts to all devices and their screens.</p>
					
					{windowWidth < 810 &&
						<a href="https://www.templatemonster.com/website-templates/astro-html-crypto-and-multipurpose-website-template-299220.html?utm_campaign=sledreact_landing_travolgi&utm_source=travolgi&utm_medium=referral&aff=travolgi" target="_blank">
							<img src={astroTheme} className="br-1 mb-3" alt="Astro Crypto and Multipurpose html5 Website template • Blog Travolgi Themes" />
						</a>
					}

					<h3>Main Features</h3>
					<ul className="list">
						<li><strong>Crypto & NFT design</strong></li>
						<li>Designed using Google Fonts</li>
						<li>Fully Responsive</li>
						<li><strong>Clean Code Structure</strong></li>
						<li>HTML5, CSS3, JS, SASS files are included</li>
						<li>Well Documentation</li>
					</ul>
				</div>

				{windowWidth > 810 &&
					<a href="https://www.templatemonster.com/website-templates/astro-html-crypto-and-multipurpose-website-template-299220.html?utm_campaign=sledreact_landing_travolgi&utm_source=travolgi&utm_medium=referral&aff=travolgi" target="_blank">
						<img src={astroTheme} className="br-1" alt="Astro Crypto and Multipurpose html5 Website template • Blog Travolgi Themes" />
					</a>
				}
			</section>

			<section>
				<span className="sr-only offset-nav" id="conclusion" />
				<h2>In conclusion</h2>
				<p>Do not be late to the <strong>Black Friday</strong> party from TemplateMonster: <strong>Get Your 50% OFF</strong>! Take advantage now and <a href="https://www.templatemonster.com/authors/travolgi?utm_campaign=blackfriday_sale&utm_source=article_website&utm_medium=referral&aff=travolgi" target="_blank">Buy our themes</a> at a favorable price!</p>
			</section>
		</>
	);
}
