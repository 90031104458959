import { Helmet } from 'react-helmet';
import PageIndex from '../../components/PageIndex';
import ThemesOverlay from '../../components/ThemesOverlay';
import sledReactTheme from '../../imgs/ads/halloween-theme.webp';

export default function HolidayDealsTravolgi() {
	return (
		<>
			<Helmet>
				<meta name="description" content="We and TemplateMonster are celebrating Halloween with some discounts that will be available on October 24-31. Will you be ready to get up to 30% OFF and don't miss the chance?" />
				<meta name="keywords" content="modern designs, html, html5 templates, UI elements, digital items, sled theme, sled halloween website, october, up to 30%, marketplace, about, story, partnership, well-designed sites, creative website, partner, sales and deals, amazing discounts, opportunity, details, about us, holiday sale, halloween, halloween discounts, discount, focus on creativity, business and individuality, marketplace sale, festive discounts, template monster, TemplateMonster, MonsterONE, website, html website" />
			</Helmet>

			<PageIndex
				links={[
					{
						hash: 'introduction',
						title: 'Introduction'
					},
					{
						hash: 'goal',
						title: 'Our goal'
					},
					{
						hash: 'partnership',
						title: 'New Partnership'
					},
					{
						hash: 'conclusion',
						title: 'In conclusion'
					}
				]}
			/>

			<section>
				<span className="sr-only offset-nav" id="introduction" />
				<p>We have only a few minutes to speak with you. You cannot imagine how many tasks our team needs to complete. Let us mention some of them. First of all, hollowing out a pumpkin to make it win every competition. Then, we need to pick the best method to apply fake blood. It is important to be careful because no one wants to look like they ate too much ketchup. Finally, preparing the scariest costume is not the last thing that makes us busy.</p>
				
				<h2>Sorry, have we forgotten to say what this fuss is about? Ladies and gentlemen, Halloween is coming.</h2>

				<p className="mb-3">You know that the spookiest year is not only about scaring the hell out of your neighbors. Many companies take advantage of this opportunity to delight their customers with <strong>amazing discounts</strong>. Perhaps the words delight and Halloween have never found themselves in one sentence, but... What have we been talking about? <strong>Sales and deals</strong>. Travolgi will not leave you without some <strong>Halloween discounts</strong>. Before we go through details, it is critical to go through some details about us.</p>

				<span className="sr-only offset-nav" id="goal" />
				<h2>Our goal</h2>
				<p className="mb-0">Our goal is to create <strong>well-designed sites</strong> that can impress people with different tastes. Our team challenges the ordinance and makes everything in its power to <strong>focus on creativity</strong>. In other words, we show the world the uniqueness of every business and individuality using our <strong>modern designs</strong>. In addition to this, you can get our <strong>HTML5 templates and UI elements</strong>.</p>
			</section>

			<section className="grid g-3">
				<span className="sr-only offset-nav" id="partnership" />
				<div>
					<h2>New Partnership</h2>
					<p>Travolgi and TemplateMonster became partners in 2022. We have never celebrated Halloween with the <strong>marketplace</strong>. However, it is so exciting to join the <strong>TemplateMonster</strong> team and spend this frightening night together. Furthermore, it is impossible to keep silent about some discounts that will make this celebration better. They will be available <strong>on October 24-31. You will be ready to get up to 30% OFF</strong> on the whole assortment of products and services. There will also be a decent discount on MonsterONE plans.</p>

					<p>If you do not know what assets deserve your attention, there is no need to worry. Below, there are some <strong>digital items</strong> that will charm you.</p>
				</div>
					
				<ThemesOverlay
					img={sledReactTheme}
					themeName="Sled React Website template for Halloween"
					category="React js, Multipurpose Theme"
					url="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=halloween_sale&utm_source=article_website&utm_medium=referral&aff=travolgi"
				/>
			</section>

			<section>
				<span className="sr-only offset-nav" id="conclusion" />
				<h2>In conclusion</h2>
				<p>It seems to us that it is time to continue getting ready for Halloween. We have almost forgotten about decorations, and it may turn out to be a catastrophe. Meet you on Halloween.</p>

				<p>Devilishly <strong>Good Sales</strong> from TemplateMonster are knocking at your Door! <a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=halloween_sale&utm_source=article_website&utm_medium=referral&aff=travolgi" target="_blank">Buy our Sled theme</a> at a favorable price! <strong>Do not miss the opportunity</strong> and run to choose your costume for Halloween.</p>

				<a href="https://www.templatemonster.com/authors/travolgi?utm_campaign=homepage_travolgi&utm_source=website_travolgi&utm_medium=referral&aff=travolgi" target="_blank" className="btn">Buy our themes now!</a>
			</section>
		</>
	);
}
