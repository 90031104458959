import { Helmet } from 'react-helmet';
import articlesList from '../data/articles';
import SectionPageTitle from '../components/SectionPageTitle';
import ArticlesPreview from '../components/ArticlesPreview';

export default function Blog() {
	return (
		<>
			<Helmet>
				<title>Blog Articles on Web Design and Web Development &bull; Travolgi Themes</title>
				<meta name="description" content="Stay up to date through the articles of Travolgi Themes: here you will find all the answers to your perplexity, the sales and promotions, our new themes, all the updates and news of the web." />
				<meta name="keywords" content="updates, news, web, articles, perplexity, answers, html, react, ui element, web designs, themes, why have a website, website, wordpress website, corporate website, responsive website, responsive, template, templatemonster" />
			</Helmet>

			<SectionPageTitle
				pageTitle={'Blog'} 
				subTitle={'Stay up to date'}
			/>

			<section className="container">
				<p className="mb-3">Stay up to date through the articles of Travolgi Themes: here you will find all the answers to your perplexity, the sales and promotions of the moment, our new themes, all the updates and news concerning the web.</p>

				<div className="grid g-2 gtc-3">
					{articlesList.map((article, idx) =>
						<ArticlesPreview
							key={idx}
							img={article.imgs.v}
							title={article.title}
							slug={article.slug}
							date={article.date}
							category={article.category}
						/>
					)}
				</div>
			</section>
		</>
	);
}