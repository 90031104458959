export default function SectionCta() {
	return (
		<section className="accent-end">
			<div className="container">
				<h1 className="d-inl-bk">Time to <span className="txt-gradient">Next Level</span></h1>

				<div className="grid gtc-2-1 g-2">
					<h3>Create now your website fast and easily with our amazing template and themes 🔥</h3>
					
					<a href="https://www.templatemonster.com/authors/travolgi?utm_campaign=homepage_travolgi&utm_source=website_travolgi&utm_medium=referral&aff=travolgi" target="_blank" className="btn">Buy our themes now</a>
				</div>
			</div>
		</section>
	);
}