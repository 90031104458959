import { useState, useEffect } from 'react';
import themesList from '../data/themes';
import ThemesOverlay from '../components/ThemesOverlay';

export default function SectionThemes({ customClass, children }) {
	const [filteredThemes, setFilteredThemes] = useState(themesList),
			[quantity, setQuantity] = useState(themesList.length),
			[query, setQuery] = useState(''),
			handleFilter = e => setQuery(e.target.value);

	useEffect(() => {
		query ?
			setFilteredThemes(themesList.filter(theme => theme.category.toLowerCase().includes(query))) :
			setFilteredThemes(themesList);
	}, [query]);

	useEffect(() => setQuantity(filteredThemes.length), [filteredThemes]);

	return (
		<section className={customClass}>
			<div className="container">
				{children}
				<p className="mb-3">Choose the right theme for you and create your own <strong>performing website at a low cost</strong> very easily and quickly <strong>using our pre-packaged themes</strong>: don't worry you will have all the <strong>documentation and support</strong> you need.</p>
				
				<div className="grid gtc-3 g-1 mb-4">
					<label htmlFor="filterBy">Filter by:</label>
					<select name="filterBy" id="filterBy" className="bg-light" onChange={handleFilter}>
						<option value="" defaultValue>- Select -</option>
						<option value="html">HTML5</option>
						<option value="react">React js</option>
						<option value="next">Next js</option>
						<option value="wordpress">Wordpress</option>
						<option value="bootstrap">Bootstrap</option>
						<option value="tailwindcss">TailwindCss</option>
						<option value="sass">SASS</option>
						<option value="ui template">UI</option>
						<option value="landing">Landing Page</option>
						<option value="ecommerce">Ecommerce</option>
					</select>

					<p className="mb-0">{quantity} results have been found</p>
				</div>
			</div>		


			<div className="grid gtc-3 g-3 pil-1 pil-lg-4">
				{filteredThemes.map((theme, idx) =>
					<ThemesOverlay
						key={idx}
						img={theme.img}
						themeName={theme.name}
						category={theme.category}
						url={theme.url}
						isNew={theme.new}
					/>
				)}
			</div>
		</section>
	);
}
