import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import articlesList from '../data/articles';
import ArticlesPreview from '../components/ArticlesPreview';
import SectionThemes from '../components/SectionThemes';
import mission from '../imgs/mission.webp';


export default function Home() {
	return (
		<>
			<Helmet>
				<title>Travolgi Themes &bull; Website Templates & Themes</title>
				<meta name="description" content="Create a stunning website in minutes with our professionally designed templates: build your online presence today and stand out from the crowd." />
				<meta name="keywords" content="travolgi themes, theme development, website templates, user interface elements, modern design, high performance, trends, template support, multipurpose website, multipurpose template, sled themes, sled, website, wordpress website, responsive website, responsive, responsive design, custom website, professional website, why have website, website for gym, web design, web designer, web developer, front end developer, easily, quickly, documentation and support, pre-packaged themes, performing website, low cost website, personal style, goal, easily, minimalists, less is more, simplicity, themes, products, search engine optimized, website template, create an experience" />
			</Helmet>

			<section id="slider">
				<div className="container">
					<h1>Your site <span className="txt-gradient">fast and easily</span></h1>
					<h2 className="w-60 fw-400">Create a stunning website in minutes with our professionally designed templates: build your online presence today and stand out from the crowd.</h2>

					<a href="https://www.templatemonster.com/authors/travolgi?utm_campaign=homepage_travolgi&utm_source=website_travolgi&utm_medium=referral&aff=travolgi" target="_blank" className="btn">Buy our themes now</a>

					<div className="flex g-1 space-between">
						<h2><span className="pictogram-top" /> Simplicity</h2>
						<h2><span className="pictogram-top" /> Responsive</h2>
						<h2><span className="pictogram-top" /> High Performance</h2>
						<h2><span className="pictogram-top" /> SEO Friendly</h2>
						<h2><span className="pictogram-top" /> Documentation</h2>
						<h2><span className="pictogram-top" /> Support</h2>
					</div>
				</div>
			</section>

			<SectionThemes customClass="accent-start">
				<h1 className="d-inl-bk">Our <span className="txt-gradient">Themes</span></h1>
			</SectionThemes>

			<section>
				<span className="sr-only offset-nav" id="blog" />
				<div className="container">
					<div className="flex g-2 space-between">
						<h1>Latest <span className="txt-gradient">Articles</span></h1>
						<Link to="blog" className="btn mb-3">SEE ALL</Link>
					</div>

					<div className="grid g-2 gtc-3">
						{articlesList.slice(0,3).map((article, idx) =>
							<ArticlesPreview
								key={idx}
								img={article.imgs.v}
								title={article.title}
								slug={article.slug}
								date={article.date}
								category={article.category}
							/>
						)}
					</div>
				</div>
			</section>

			<section className="bg-waveline">
				<div className="container txt-center">
					<h3>Our mission is to create amazing website</h3>
					<h1 className="fs-100">Template</h1>
				</div>
			</section>
			
			<section>
				<div className="container">
					<div className="grid g-2">
						<div className="fingerprint flex">
							<img src={mission} alt="About Us • Travolgi Themes" />
						</div>

						<article>
							<h1>About <span className="txt-gradient">Us</span></h1>
							<h2>
								<em>We design our templates drawing inspiration from real projects, while keeping short-lived trends outside of the office.</em>
							</h2>
							<p>We are minimalists, those designers obsessed with "<strong>less is more</strong>", certain that simplicity makes the difference in a world that bombards us with information. We give great importance to code quality, striving to optimize our themes to the fullest, creating products that are easy to use, maintain, modify and above all, fast and <strong>search engine optimized</strong>.</p>
							<p className="mb-3">Our one and only goal is to give you what you deserve! Not just a <strong>website template</strong>, not just a simple tool, but to create an experience.</p>

							<HashLink to="#themes" className="btn self-end">OUR THEMES</HashLink>
						</article>
					</div>
				</div>
			</section>
		</>
	);
}