import { Helmet } from 'react-helmet';
import PageIndex from '../../components/PageIndex';
import internetResearch from '../../imgs/blog/internet-research.svg';
import websiteErrors from '../../imgs/blog/website-errors.svg';

export default function WhyHaveWebsite() {
	return (
		<>
			<Helmet>
				<meta name="description" content="Advantages and disadvantages of the website, people look for everything on the internet: this is why it is essential to have a well-made online showcase" />
				<meta name="keywords" content="why have a website, showcase site, landing page, blog, ecommerce, website, performing website, wordpress site, corporate website, well designed website, website error, website malfunctions, responsive website, responsive, website from telephone, responsive design, design, custom site, professional site, online showcase, essential site services, domain, domain name, hosting, communication, customer communication, brand awareness, time saving, branding, web agency, web developer, web professional, easily, quickly, documentation and support, pre-packaged themes, performing website, low cost" />
			</Helmet>

			<PageIndex
				links={[
					{
						hash: 'introduction',
						title: 'Introduction'
					},
					{
						hash: 'how-work',
						title: 'But how does a website work?'
					},
					{
						hash: 'convenience',
						title: 'Is it worth having a website or not?'
					},
					{
						hash: 'how-advantage',
						title: 'How can I take advantage of my website?'
					},
					{
						hash: 'advantages-disadvantages',
						title: 'Advantages vs disadvantages'
					},
					{
						hash: 'they-say',
						title: 'Everyone tells me it\'s just an expense, because?'
					},
					{
						hash: 'conclusion',
						title: 'In conclusion'
					}
				]}
			/>

			<section>
				<span className="sr-only offset-nav" id="introduction" />
				<p>Having a website offers many advantages, which is why there are various types such as <strong>the showcase, the landing page, the blog, the ecommerce, the social network, the web portals</strong> etc.</p>
				<p>People, nowadays, look for anything on the internet and not being there is equivalent to not existing. Having a <strong>website is definitely essential</strong> for any business... and I would say mandatory if its competitors already have one.</p>
			</section>

			<section>
				<span className="sr-only offset-nav" id="how-work" />
				<h2>But how does a website work?</h2>
				<p>A <strong>website</strong> is nothing more than a collection of interconnected files that can perform many functions at the same time, saving us <strong>a lot of time</strong>. However, in order to function and be seen by users via their internet-connected devices, a website needs some <strong>essential services</strong> such as:</p>
				<ul className="list">
					<li>a <strong>domain name</strong>, <em>such as travolgi.com</em></li>
					<li>a <strong>hosting</strong> service to upload files</li>
					<li>a <strong>database</strong> for data (if necessary)</li>
					<li>of <strong>dedicated emails</strong>, <em>such as test@travolgi.com (optional)</em></li>
				</ul>
			</section>
				
			<section>
				<span className="sr-only offset-nav" id="convenience" />
				<h2>Is it worth having a website or not?</h2>
				<p className="mb-3">The convenience and usefulness of owning one is closely linked to your needs, your goals and the competition you find yourself facing. See it as a <strong>communication and dissemination tool to reach your goals more easily and quickly</strong>.</p>

				<div className="grid g-2 mb-3">
					<img src={internetResearch} className="w-60" alt="People use the internet to find information • Why you should have a website • Blog Travolgi Themes" />

					<div>
						<p>People use the internet on a daily basis to find information, activities, reviews and advice, products and services, to choose which products to buy and where to buy them.</p>
						<p>If you want to be competitive you must have a well-made <strong>online showcase</strong> that brings your <strong>customers and potential customers</strong> to know you and choose you 7 days a week and 24 hours a day.</p>
					</div>
				</div>
			</section>

			<section>
				<span className="sr-only offset-nav" id="how-advantage" />
				<h2>How can I take advantage of my website?</h2>
				<ul className="list">
					<li>Creating <strong>brand awareness</strong>, or making your business known and found</li>
					<li><strong>Advertising and selling</strong> products, services and consulting</li>
					<li>Simplifying the request for information</li>
					<li>Speeding up and simplifying <strong>customer communication</strong></li>
					<li>Creating a <strong>community</strong> of interested people</li>
				</ul>
			</section>

			<section>
				<span className="sr-only offset-nav" id="advantages-disadvantages" />
				<h2>Advantages and disadvantages</h2>
				<h3>Advantages</h3>
				<ul className="list">
					<li><strong>You save time</strong> by informing, educating and responding to customers and/or potential customers</li>
					<li><strong>Break down barriers of time and space</strong> by approaching new customers from all over the world</li>
					<li>You are <strong>always open</strong> and here your website makes the difference because <strong>does not have closing days and times</strong></li>
					<li><strong>Eliminate non-customers</strong> because they understand what you are doing and that you are not what they were looking for</li>
					<li><strong>Retrieve information</strong> discovering new requests or deficiencies in your service</li>
					<li><strong>Communicate quickly</strong> ideas and news</li>
				</ul>

				<h3>Disadvantages</h3>
				<ul className="list">
					<li>Requires <strong>skill and experience</strong>, you need to know how to do it and do it right</li>
					<li>You have <strong>annual costs</strong> for the domain, the mailboxes and a performing hosting</li>
					<li>You may <strong>reach too many people</strong> and not be able to answer and handle everyone</li>
					<li><strong>You have to change your mindset</strong> going from <em>"I do this for my customers"</em> to <strong>"what my customers are looking for"</strong> so as to understand that searches they could do on the web</li>
				</ul>
			</section>

			<section>
				<span className="sr-only offset-nav" id="they-say" />
				<h2>Everyone tells me it's just an expense, because?</h2>
				<p>The causes can be many but here are some questions that might give you some answers:</p>

				<div className="grid g-2 mb-3">
					<ul className="list">
						<li>Is the <strong>design</strong> and structure of the website <strong>well designed</strong>? Is it nice to look at and easy to use?</li>
						<li>Does the website have harmonious photos and colors? Are the texts free of spelling errors?</li>
						<li>If I open the <strong>website from my phone</strong>, can I view everything and navigate without problems? And from a computer?<span className="sr-only">Is it a responsive website?</span></li>
						<li>Doesn't it have any <strong>errors</strong> that can upset users and make them run away?</li>
						<li>If someone searches for it on Google, does the site appear in the <strong>results</strong>?</li>
					</ul>
					
					<img src={websiteErrors} className="w-60" alt="Website malfunctions • Why you should have a website • Blog Travolgi Themes" />
				</div>

				<p>If you answered <b>NO</b> to just one of these questions it is normal that the site in question has become more of an expense than a profit, as you can see there are many reasons and to be honest to have an old website, not visible from the phone, very slow in loading, full of errors or with obsolete data inserted is counterproductive so much so that... it is better not to have it at all.</p>
			</section>
				
			<section>
				<span className="sr-only offset-nav" id="conclusion" />
				<h2>In conclusion</h2>
				<p>If you want to have a <strong>performing website that gives you some results</strong>, first of all, there must be no <strong>malfunctions</strong>, outdated designs or data. Small details make a sea of difference and having a <strong>specialized figure to take care of them for you</strong> is the best choice you can make: a <strong>technically competent and up-to-date professional</strong> who takes care of full autonomy of your website will not make you waste neither time nor money.</p>
				
				<p>Alternatively, you can always create your own <strong>performing website at a low cost</strong> very easily and quickly <a href="https://www.templatemonster.com/authors/travolgi?utm_campaign=homepage_travolgi&utm_source=website_travolgi&utm_medium=referral&aff=travolgi" target="_blank"><strong>using our pre-packaged themes</strong></a>: don't worry you will have all the <strong>documentation and support</strong> you need.</p>
				
				<a href="https://www.templatemonster.com/authors/travolgi?utm_campaign=homepage_travolgi&utm_source=website_travolgi&utm_medium=referral&aff=travolgi" target="_blank" className="btn">Buy our themes now!</a>
			</section>
		</>
	);
}