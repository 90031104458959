import { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import SectionCta from './components/SectionCta';
import Footer from './components/Footer';
import ScrollTop from './components/ScrollTop';
import Home from './views/Home';
import Themes from './views/Themes';
import Blog from './views/Blog';
import Article from './views/Article';
import WhatUse from './views/WhatUse';
import CookiePolicy from './views/CookiePolicy';
import PageNotFound from './views/PageNotFound';


export default function App() {
	const location = useLocation();
	useEffect(() => {
		!location.hash ?
			setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0) :
			localStorage.removeItem('position');
	}, [location]);
	
	return (
		<>
			<Header />

			<main>
				<Routes location={location} key={location.pathname}>
					<Route path='/'>
						<Route index element={<Home />} />
						<Route path='themes' element={<Themes />} />
						<Route path='blog'>
							<Route index element={<Blog />} />
							<Route path=":articleName" element={<Article />} />
						</Route>
						<Route path='what-we-use' element={<WhatUse />} />
						<Route path='cookie-policy' element={<CookiePolicy />} />
						<Route path='/*' element={<PageNotFound />} />
					</Route>
				</Routes>
			</main>

			<ScrollTop />
			<SectionCta />
			<Footer />
		</>
	);
}
