import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import SectionPageTitle from '../components/SectionPageTitle';
import err404 from '../imgs/404.svg'

export default function PageNotFound() {
	return (
		<>
			<Helmet>
				<title>Page Not Found &bull; Travolgi Themes &bull; Website Templates & Themes</title>
			</Helmet>

			<SectionPageTitle
				pageTitle={'Page Not Found'}
			/>

			<section className="container grid g-2">
				<img src={err404} alt="404 Page Not Found • Travolgi Themes" />

				<div>
					<h1>404 Error</h1>
					<h2 className="mb-0">Page Not Found</h2>
					<p className="mb-3">OPS! We couldn't find what you were looking for.</p>
					<Link to="/" className="btn">Back to Home</Link>
				</div>
			</section>
		</>
	);
}