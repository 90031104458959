import { Helmet } from 'react-helmet';

export default function CookiePolicy() {
	return (
		<>
			<Helmet>
				<title>Cookie Policy &bull; Travolgi Themes &bull; Website Templates & Themes</title>
			</Helmet>

			<section className="container">
				<h1>Cookie Policy</h1>
				<p>This Cookie Policy explains what cookies are and how they are used, the types of cookies used, the data collected through cookies, how this information is used, and how to manage cookie settings.</p>

				<h2>What Are Cookies?</h2>
				<p>Cookies are small text files used to store small pieces of information. They are stored on the user's device when the website is loaded in their browser. These cookies help the website to function properly, make it more secure, provide a better user experience, and understand how the website behaves by analyzing what works and where improvements are needed.</p>

				<h2>Cookie Usage</h2>
				<p>Like most online services, our website uses both first-party and third-party cookies for various purposes. First-party cookies are primarily necessary for the website to function properly and do not collect any personally identifiable information. Third-party cookies are primarily used to understand how the website behaves, how the user interacts with our website, keep our services secure, provide relevant advertisements to the user, offer a better and enhanced user experience, and expedite the user's future interactions with our website.</p>

				<h2>Types of Cookies Used</h2>
				<div className="cky-audit-table-element" />

				<h2>Managing Cookie Preferences</h2>
				<p>The user can change their cookie preferences at any time by clicking the button below. This will allow them to return to the cookie consent banner and change their preferences or revoke their consent immediately.</p>
				<a className="cky-banner-element">Cookie Settings</a>
				<p>Additionally, different browsers offer different methods to block and delete cookies used by websites. The user can change their browser settings to block/delete cookies, and we encourage the user to refer to their browser's official support documentation for further information.</p>
				<p>Last updated: September 4, 2023</p>

				<small>Cookie Policy generated by <a href="https://www.cookieyes.com/welcome/?ref=njawmjg" target="_blank" rel="noreferrer">CookieYes</a>.</small>
			</section>
		</>
	);
}