import { Helmet } from 'react-helmet';
import { hardware, hosting, software, policy, images } from '../data/whatUse';
import SectionPageTitle from '../components/SectionPageTitle';

export default function WhatUse() {
	return (
		<>
			<Helmet>
				<title>What does a Web developer use &bull; Travolgi Themes</title>
				<meta name="description" content="A listing of hardware, software, and other tools that web developer and web designer uses on a regular basis" />
				<meta name="keywords" content="hardware, software, hosting, domains, computer, monitor web design, web designs, template, templatemonster" />
			</Helmet>

			<SectionPageTitle
				pageTitle={'What We Use'} 
				subTitle={'Hardware, software, & other'}
			/>

			<section className="container">				
				<h1>Hosting provider</h1>
				<div className="grid g-2 gtc-3 mb-4">
					{hosting.map((article, idx) =>
						<a href={article.url} target="_blank" className="txt-white w-100" key={idx}>
							<img src={article.img} alt={`${article.title} • What does a Web developer use • Travolgi Themes`} className="mb-1" style={{ width: 'auto', maxHeight: '5rem' }} />
							<h3>{article.title}</h3>
							{/*
							<p>{article.text}</p>
							*/}
						</a>
					)}
				</div>

				<h1>Policy Generator & Cookie Banner</h1>
				<div className="grid g-2 gtc-3 mb-4">
					{policy.map((article, idx) =>
						<a href={article.url} target="_blank" className="txt-white w-100" key={idx}>
							<img src={article.img.default} alt={`${article.title} • What does a Web developer use • Travolgi Themes`} className="mb-1" style={{ width: 'auto', maxHeight: '5rem' }} />
							<h3>{article.title}</h3>
							{/*
							<p>{article.text}</p>
							*/}
						</a>
					)}
				</div>

				<h1>Tecnologies and Software</h1>
				<div className="grid g-2 gtc-3 mb-4">
					{software.map((article, idx) =>
						<a href={article.url} target="_blank" className="txt-white w-100" key={idx}>
							<img src={article.img.default} alt={`${article.title} • What does a Web developer use • Travolgi Themes`} className="mb-1" style={{ width: 'auto', maxHeight: '5rem' }} />
							<h3>{article.title}</h3>
							{/*
							<p>{article.text}</p>
							*/}
						</a>
					)}
				</div>

				<h1>Website Images</h1>
				<div className="grid g-2 gtc-3 mb-4">
					{images.map((article, idx) =>
						<a href={article.url} target="_blank" className="txt-white w-100" key={idx}>
							<img src={article.img.default} alt={`${article.title} • What does a Web developer use • Travolgi Themes`} className="mb-1" style={{ width: 'auto', maxHeight: '5rem' }} />
							<h3>{article.title}</h3>
							{/*
							<p>{article.text}</p>
							*/}
						</a>
					)}
				</div>
				
				<h1>Developers Hardware</h1>
				<div className="grid g-2 gtc-3 mb-4">
					{hardware.map((article, idx) =>
						<a href={article.url} target="_blank" className="txt-white w-100" key={idx}>
							<img src={article.img} alt={`${article.title} • What does a Web developer use • Travolgi Themes`} className="mb-1" />
							<h3>{article.title}</h3>
							{/*
							<p>{article.text}</p>
							*/}
						</a>
					)}
				</div>
			</section>
		</>
	);
}