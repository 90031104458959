import { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import logo from '../imgs/travolgi-logo.webp';

export default function Header() {
	const refNavBar = useRef(),
			refHeader = useRef(),
			[isOpen, setIsOpen] = useState(false),
			handleToggle = () => setIsOpen(!isOpen);

	useEffect(() => {
		const handleClick = e => {
			if (!refHeader.current.contains(e.target)) {
				setIsOpen(false);
			}
		};
		document.addEventListener('click', handleClick);

		return () => document.removeEventListener('click', handleClick);
	}, []);
	
	return (
		<header ref={refHeader}>
			<div className="container g-2 pbk-1">
				<Link to="/">
					<img src={logo} alt="Travolgi Themes logo" />
				</Link>

				<button
					className="nav-toggle"
					aria-controls={refNavBar}
					aria-expanded={isOpen}
					onClick={handleToggle}
				>
					<span className="sr-only">Menu</span>
					<div className="bar1" />
					<div className="bar2" />
					<div className="bar3" />
				</button>

				<nav
					id="navbar"
					ref={refNavBar}
					data-visible={isOpen}
					onFocus={handleToggle}
				>
					<ul className="flex g-2">
						<li><HashLink to="/">Home</HashLink></li>
						<li><Link to="/themes">Themes</Link></li>
						<li><Link to="/blog">Blog</Link></li>
						<li><Link to="/what-we-use">What We Use</Link></li>
						<li><a href="https://www.templatemonster.com/authors/travolgi?utm_campaign=homepage_travolgi&utm_source=website_travolgi&utm_medium=referral&aff=travolgi" target="_blank" className="btn">BUY THEMES</a></li>
					</ul>
				</nav>
			</div>
		</header>
	);
}